<template>
  <div class="w-100">

    <b-card>
      <b-card-header>
        <h4>{{$t('Incomes')}}</h4>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
            type="line"
            height="400"
            :options="getLineOptions(incomeLabels)"
            :series="incomeSeries"
        />
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <h4>{{$t('Outcomes')}}</h4>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
            type="line"
            height="400"
            :options="getLineOptions(outcomeLabels)"
            :series="outcomeSeries"
        />
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <h4>{{$t('Collection')}}</h4>
      </b-card-header>
      <b-card-body>
        <vue-apex-charts
            type="line"
            height="400"
            :options="getLineOptions(collectionLabels)"
            :series="collectionSeries"
        />
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import { getIncomesAndOutcomes } from '@/api/reports/payments'
import moment from 'moment'
import { $themeColors } from '../../../themeConfig'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'IncomesAndOutcomes',
  components:{
    VueApexCharts,
  },
  data() {
    return {
      incomes: [],
      outcomes: [],
      collections: [],
      incomeLabels: [],
      incomeSeries: [],
      outcomeLabels: [],
      outcomeSeries: [],
      collectionLabels: [],
      collectionSeries: [],
      chartLabels: [],
      chartSeries: [],
      lineChartSimple: {
        series: [
          {
            data: [],
          },
        ],
      },
    }
  },
  mounted(){
    this.fetchReport()
  },
  methods: {
    fetchReport() {
      getIncomesAndOutcomes().then(res => {
        this.incomes = res.data.incomes;
        const incomeEntries = Object.entries(this.incomes);
        for(const [key,value] of incomeEntries) {
          this.incomeLabels.push(moment().months(key-1).format('MMMM'))
          this.incomeSeries.push(value[0].total);
        }
        this.incomeSeries = [{data: this.incomeSeries}]
        this.outcomes = res.data.outcomes;
        const outcomeEntries = Object.entries(this.outcomes);
        for(const [key, value] of outcomeEntries) {
          this.outcomeLabels.push(moment().months(key-1).format('MMMM'));
          this.outcomeSeries.push(value[0].total);
        }
        this.outcomeSeries = [{data:this.outcomeSeries}]
        this.collections = res.data.collections;
        const collectionEntries = Object.entries(this.collections);
        for(const [key, value] of collectionEntries) {
          this.collectionLabels.push(moment().months(key-1).format('MMMM'));
          this.collectionSeries.push(value[0].total);
        }
        this.collectionSeries = [{data: this.collectionSeries}]
      })
    },
    getLineOptions(labels) {
      return {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
            }%</span></div>`
          },
        },
        xaxis: {
          categories: labels,
        },
        yaxis: {
          // opposite: isRtl,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
