import request from '../../libs/axios'


export function getPaymentReports(params) {
  return request({
    url: '/api/get-payment-reports',
    method: 'get',
    params,
  })
}


export function getIncomesAndOutcomes(params) {
  return request({
    url:'/api/get-incomes-and-outcomes',
    method:'get',
    params,
  })
}
